import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO/SEOPage';
import Layout from '../components/shared/layout';
import { wrapper1200, color, breakpoints } from '../utils/style';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import ButtonLink from '../components/shared/ButtonLink';

const Wrapper = styled('div')`
  ${wrapper1200}
  .info-wrapper {
    margin: 30px auto;
    padding: 60px 30px;
    background: ${color.transparentWhite1};
    border-radius: 16px;
    text-align: center;
    h1 {
      margin:0 0 30px;
    }
    p {
      margin: 30px 0;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .info-wrapper {
      padding: 30px 15px;
    }
  }
  
`;

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title='You have successfully registered!'/>
    <Wrapper>
      <div className={"info-wrapper"}>
        <h1>Bạn đã đăng ký thành công!</h1>
        <p>Vui lòng kiểm tra email để kích hoạt tài khoản và chơi vòng quay miễn phí</p>
        <ButtonLink to="/" text="Trở về trang chủ" ariaText="Trở về trang chủ"/>
      </div>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    missing: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
          fluid(maxWidth: 480) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }   
  }
`;
